import { useRouter } from "next/router";

import { useRoutingUtils } from "@hooks/useRoutingUtils";

export const useSetQueryParameters = () => {
  const router = useRouter();
  const routingUtils = useRoutingUtils();

  return targetParams => {
    const url = router.asPath.match(/^[^?]+/)[0];

    // @see https://github.com/vercel/next.js/issues/9473
    router.push(
      routingUtils.buildPathname(router.pathname, targetParams),
      routingUtils.buildPathname(url, targetParams)
    );
  };
};
