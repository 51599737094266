class LeadTimeHelper {
  calculateLeadTime(preconfiguration, selectedAccessories, isHomeDelivery) {
    const { leadTimes } = preconfiguration;

    // Handle edge cases gracefully
    if (!leadTimes) {
      return {
        leadTime: null,
        leadTimeDate: null,
      };
    }

    // Default lead time is lead time without accessories
    const defaultLeadTime = leadTimes.find(
      ({ accessoryId }) => accessoryId === null
    );

    // Find the highest leadTime / leadTimeHomeDelivery from the selectedAccessories (higher lead time wins)
    return selectedAccessories.reduce((acc, cur) => {
      const currentLeadTime = leadTimes.find(
        ({ accessoryId }) => accessoryId === cur
      );

      // This really should't happen, but if it does, lets just ignore the selected accessory lead time
      if (!currentLeadTime) {
        return acc;
      }

      const targetAttribute = isHomeDelivery
        ? "leadTimeHomeDelivery"
        : "leadTime";

      return currentLeadTime[targetAttribute] > acc[targetAttribute]
        ? currentLeadTime
        : acc;
    }, defaultLeadTime);
  }

  getLeadTimes(preconfiguration, accessoryIds) {
    const getLead = isHomeDelivery =>
      this.calculateLeadTime(preconfiguration, accessoryIds, isHomeDelivery);

    return {
      homeDelivery: getLead(true).leadTimeDate,
      pickupLocation: getLead(false).leadTimeHomeDeliveryDate,
    };
  }
}

module.exports = LeadTimeHelper;
