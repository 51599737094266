import React, { useState, useMemo } from "react";
import styled from "styled-components";
import getConfig from "next/config";
import { arrayOf, bool, func, object, string, number } from "prop-types";
import { Trans, useTranslation } from "@lib/i18n";

import Typography from "@components/shared/Typography";
import SimpleButton from "@components/shared/SimpleButton";
import ArrowRightIcon from "@components/shared/Icons/ArrowRight";
import LegalMentionModal from "./LegalMentionModal";
import AnimatedTuneIcon from "@components/shared/Icons/AnimatedTune";

import FinanceDisplayBlocks, {
  getDisplayBlocksVariant,
} from "@components/Financing/FinanceDisplayBlocks";

import { useOpenFinanceWidget } from "@hooks/useOpenFinanceWidget";
import { useIsB2BSelected } from "@hooks/useIsB2BSelected";
import { desktop, color, fontSize, fontWeight } from "@components/shared/utils";
import { useGetFormattedPrice } from "@hooks/useGetFormattedPrice";
import { useEcoBonusSelector } from "@redux/reducers/appConfig";
import { getIsVACJourneySelected } from "@shared/v2/lib/getIsVACJourneySelected";
import { getParsedLegalMention } from "@shared/v2/lib/getParsedLegalMention";
import getPriceAlgorithm from "@shared/PriceAlgorithm/getPriceAlgorithm";
import { dealType } from "types";

const { publicRuntimeConfig } = getConfig();

const { COUNTRY } = publicRuntimeConfig;

const LegalMentionLink = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;
  font-style: italic;
  margin-top: 1rem;
`;

const LegalMentionParagraph = styled(Typography)`
  font-size: ${fontSize("sm")};
`;

const CustomizeContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;

  ${desktop`
    border: none;
    text-align: left;
    text-decoration: none;
  `}
`;

const StyledArrowRightIcon = styled(ArrowRightIcon)`
  margin-left: 0.5rem;
  margin-top: -5px;
  ${desktop`
    margin-left: 1rem;
  `}
`;

const ErrorMessage = styled(Typography)`
  align-items: center;
  color: ${color("black")};
  display: flex;
  justify-content: center;
  min-height: 10rem;
`;

const StyledAnimatedTuneIcon = styled(AnimatedTuneIcon)`
  color: ${color("black")};
  margin-right: 1rem;
`;

const StyledFinancingTypography = styled(Typography)`
  font-size: ${fontSize("md")};
  font-weight: ${fontWeight("bold")};
  text-transform: uppercase;
`;

const FinanceFinancing = ({
  shouldDisplayError,
  financialProductLabel,
  displayBlocks,
  legalMention,
  customizeButtonText,
  journey,
  isWidgetEditable,
  isModifiable,
  onModifyButtonClick,
  withDeliveryInfo,
  isQuantityVisible,
  vehicleQuantity,
  isReadOnly,
  deal,
}) => {
  const { t } = useTranslation();
  const openFinanceWidget = useOpenFinanceWidget();
  const ecoBonus = useEcoBonusSelector();
  const priceAlgorithm = getPriceAlgorithm(COUNTRY);
  const getFormattedPrice = useGetFormattedPrice();
  const isB2BSelected = useIsB2BSelected();

  const shouldDisplayModalLink = !getIsVACJourneySelected(journey);

  const parsedLegalMentions = useMemo(() => {
    return getParsedLegalMention(legalMention);
  }, [legalMention]);

  const [isLegalModalOpen, setIsLegalModalOpen] = useState(false);

  return (
    <>
      {shouldDisplayError ? (
        <ErrorMessage>{t("financeDataErrorMessage")}</ErrorMessage>
      ) : (
        <>
          <Typography bold size="md">
            {financialProductLabel}
          </Typography>
          <FinanceDisplayBlocks
            displayBlocks={displayBlocks}
            variant={getDisplayBlocksVariant(journey)}
            vehicleQuantity={vehicleQuantity}
            isReadOnly={isReadOnly}
            deal={deal}
          />
        </>
      )}
      {shouldDisplayModalLink ? (
        <LegalMentionLink size="sm" onClick={() => setIsLegalModalOpen(true)}>
          {t("basket.financing.legalMention")}
        </LegalMentionLink>
      ) : (
        parsedLegalMentions.map((paragraph, index) => (
          <LegalMentionParagraph key={index}>{paragraph}</LegalMentionParagraph>
        ))
      )}
      {isWidgetEditable && (
        <>
          <SimpleButton
            onClick={openFinanceWidget}
            data-id="financefinancing-openfinancewidget"
          >
            <CustomizeContainer>
              <StyledAnimatedTuneIcon height="28px" width="28px" />
              <StyledFinancingTypography>
                {customizeButtonText}
              </StyledFinancingTypography>
              <StyledArrowRightIcon size="sm" />
            </CustomizeContainer>
          </SimpleButton>
          <Typography size="sm" margin="0 0 2rem 0">
            {t("basket.financing.customizeInfo")}
          </Typography>
        </>
      )}
      {ecoBonus.enabled && (
        <Typography size="sm" margin="1rem 0 0 0">
          {isB2BSelected
            ? isQuantityVisible && vehicleQuantity > 1
              ? t("price.b2b.multipleVehiclesEcologicalBonus", {
                  ecoBonusPerVehicle: getFormattedPrice(
                    Number(ecoBonus.netAmount)
                  ),
                  quantity: vehicleQuantity,
                  total: getFormattedPrice(
                    Number(
                      priceAlgorithm.times(ecoBonus.netAmount, vehicleQuantity)
                    )
                  ),
                })
              : t("price.b2b.ecologicalBonus", {
                  amount: getFormattedPrice(Number(ecoBonus.netAmount)),
                })
            : t("price.b2c.ecologicalBonus", {
                amount: getFormattedPrice(Number(ecoBonus.netAmount)),
              })}
        </Typography>
      )}

      {withDeliveryInfo && (
        <Typography size="sm" margin="0.5rem 0">
          <Trans i18nKey="price.deliveryInfo.financing" />
        </Typography>
      )}
      <LegalMentionModal
        isOpen={isLegalModalOpen}
        onClose={() => setIsLegalModalOpen(false)}
        legalMention={legalMention}
      />
      {isModifiable && (
        <SimpleButton
          withArrow
          onClick={onModifyButtonClick}
          data-id="basket-financing-modify-button"
        >
          {t("general.modify")}
        </SimpleButton>
      )}
    </>
  );
};

FinanceFinancing.propTypes = {
  shouldDisplayError: bool.isRequired,
  financialProductLabel: string.isRequired,
  displayBlocks: arrayOf(object).isRequired,
  legalMention: arrayOf(object).isRequired,
  customizeButtonText: string.isRequired,
  journey: string.isRequired,
  isWidgetEditable: bool,
  isModifiable: bool,
  onModifyButtonClick: func,
  withDeliveryInfo: bool,
  isQuantityVisible: bool,
  vehicleQuantity: number,
  isReadOnly: bool,
  deal: dealType,
};

export default FinanceFinancing;
