import React from "react";
import { string } from "prop-types";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useTranslation } from "@lib/i18n";

import Typography from "./Typography";
import LoadingDots from "./LoadingDots";

const AnimatedTypography = motion.custom(Typography);

const StyledTypography = styled(AnimatedTypography)`
  display: inline-block;
  text-transform: capitalize;
  font-size: inherit;
  color: inherit;
`;

const HorizontalLoader = ({ className }) => {
  const { t } = useTranslation();

  return (
    <StyledTypography
      className={className}
      variants={{
        start: { opacity: 0 },
        animated: {
          opacity: 1,
          transition: { staggerChildren: 0.5, delayChildren: 0.3 },
        },
      }}
      animate="animated"
      initial="start"
    >
      {t("loading")} <LoadingDots />
    </StyledTypography>
  );
};

HorizontalLoader.propTypes = {
  className: string,
};

export default HorizontalLoader;
