import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import BaseIcon from "../BaseIcon";

const StyledBaseIcon = styled(BaseIcon)`
  cursor: pointer;
`;

const AnimatedTuneIcon = props => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledBaseIcon
      viewBox="0 0 77 62"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      <g fill="currentColor">
        <path d="M4.40625 4.58498H73V11.2771H4.40625V4.58498Z" />
        <path d="M4.40625 23.8247H73V30.5168H38.7031H4.40625V23.8247Z" />
        <path d="M4.40625 43.0644H73V49.7565H4.40625V43.0644Z" />
        <motion.path
          d="M14.4444 0.402435H29.5015V15.4596H14.4444V0.402435Z"
          variants={{ shuffleIn: { x: 20 }, shuffleOut: { x: 0 } }}
          animate={isHovered ? "shuffleIn" : "shuffleOut"}
        />
        <motion.path
          d="M47.9047 19.6421H62.9619V34.6993H47.9047V19.6421Z"
          variants={{ shuffleIn: { x: -20 }, shuffleOut: { x: 0 } }}
          animate={isHovered ? "shuffleIn" : "shuffleOut"}
        />
        <motion.path
          d="M14.4444 38.8819H29.5015V53.939H14.4444V38.8819Z"
          variants={{ shuffleIn: { x: 20 }, shuffleOut: { x: 0 } }}
          animate={isHovered ? "shuffleIn" : "shuffleOut"}
        />
      </g>
    </StyledBaseIcon>
  );
};

export default AnimatedTuneIcon;
