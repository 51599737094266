import React, { useState } from "react";
import styled from "styled-components";
import { arrayOf, bool, object, string, number } from "prop-types";
import { useTranslation } from "@lib/i18n";

import CollapsableContainer, {
  CollapsableContainerHeader,
  CollapsableContainerTitleContainer,
  CollapsableContainerSubTitle,
} from "@components/shared/CollapsableContainer";
import Typography from "@components/shared/Typography";
import { usePrimaryColor } from "@hooks/usePrimaryColor";

import { color, fontSize, fontWeight } from "@components/shared/utils";
import { JOURNEY_TYPE } from "@shared/constants";

import FinanceDisplayBlocks, {
  DISPLAY_BLOCKS_VARIANT,
} from "./FinanceDisplayBlocks";

const StyledCollapsableContainer = styled(CollapsableContainer)`
  padding: 1.5rem 0 0 !important;
  margin-top: 1.5rem;
  border-top: 1px solid ${color("grey")};

  & ${CollapsableContainerHeader} {
    color: ${({ primaryColor }) => primaryColor};
    padding: ${({ subTitle }) => (subTitle ? "0 !important" : "1.5rem 0")};
  }

  & ${CollapsableContainerSubTitle} {
    color: ${color("black")};
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  }

  & ${CollapsableContainerTitleContainer} {
    margin: 0;
  }
`;

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: ${fontSize("md")};
  font-weight: ${fontWeight("bold")};
`;

const ErrorMessage = styled(Typography)`
  align-items: center;
  color: ${color("black")};
  display: flex;
  justify-content: center;
  min-height: 10rem;
`;

const TotalFinanceFinancing = ({
  shouldDisplayError,
  financialProductLabel,
  displayBlocks,
  journey,
  isQuantityVisible,
  vehicleQuantity,
}) => {
  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();
  const [isOpen, setOpen] = useState(false);

  const getDisplayBlocksVariant = journey => {
    switch (journey) {
      case JOURNEY_TYPE.LOA:
        return DISPLAY_BLOCKS_VARIANT.COMPACT;

      case JOURNEY_TYPE.VAC:
        return DISPLAY_BLOCKS_VARIANT.WORDY;

      case JOURNEY_TYPE.LLD:
      default:
        return DISPLAY_BLOCKS_VARIANT.DEFAULT;
    }
  };

  const subtitle =
    vehicleQuantity > 1
      ? t("basket.totalPaymentSubtitle.plural", {
          count: vehicleQuantity,
        })
      : t("basket.totalPaymentSubtitle.singular", {
          count: vehicleQuantity,
        });

  return (
    <StyledCollapsableContainer
      primaryColor={primaryColor}
      isOpen={isOpen}
      onChange={() => setOpen(!isOpen)}
      subTitle={subtitle}
      title={
        <TitleContainer>
          <span>{t("basket.totalPaymentTitle")}</span>
        </TitleContainer>
      }
    >
      {shouldDisplayError ? (
        <ErrorMessage>{t("financeDataErrorMessage")}</ErrorMessage>
      ) : (
        <>
          <Typography bold size="md">
            {financialProductLabel}
          </Typography>
          <FinanceDisplayBlocks
            displayBlocks={displayBlocks}
            variant={getDisplayBlocksVariant(journey)}
            isQuantityVisible={isQuantityVisible}
            vehicleQuantity={vehicleQuantity}
          />
        </>
      )}
    </StyledCollapsableContainer>
  );
};

TotalFinanceFinancing.propTypes = {
  shouldDisplayError: bool.isRequired,
  financialProductLabel: string.isRequired,
  displayBlocks: arrayOf(object).isRequired,
  journey: string.isRequired,
  isQuantityVisible: bool,
  vehicleQuantity: number,
};

export default TotalFinanceFinancing;
