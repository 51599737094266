import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { bool, string, func, number, arrayOf } from "prop-types";

import CollapsableContainer from "@components/shared/CollapsableContainer";
import ErrorMessage from "@components/shared/ErrorMessage";
import CircularLoader from "@components/shared/Icons/CircularLoader";
import FinancingTitle from "@components/Financing/FinancingTitle";

import { useIsSCFJourneySelected } from "@hooks/useIsSCFJourneySelected";

import CashFinancing from "./CashFinancing";
import FinanceFinancing from "./FinanceFinancing";
import TotalFinanceFinancing from "./TotalFinanceFinancing";

import { JOURNEY_TYPE } from "@shared/constants";
import { getIsCashJourneySelected } from "@shared/v2/lib/getIsCashJourneySelected";
import { getIsLeasingJourneySelected } from "@shared/v2/lib/getIsLeasingJourneySelected";
import { color } from "@components/shared/utils";
import { useGetJourneySpecificConfig } from "@hooks/useGetJourneySpecificConfig";

import {
  useLOAPriceBreakdownSelector,
  useLLDPriceBreakdownSelector,
  useVACPriceBreakdownSelector,
  useSCFPriceBreakdownSelector,
  useVehicleQuantitySelector,
} from "@redux/reducers/deal";

import {
  useLLDPriceBreakdownRequestStatusSelector,
  useLOAPriceBreakdownRequestStatusSelector,
  useVACPriceBreakdownRequestStatusSelector,
  useSCFPriceBreakdownRequestStatusSelector,
} from "@redux/reducers/requests";

import {
  dealItemType,
  financingLOAData,
  financingCashData,
  financingLLDData,
  financingVACData,
  financingSCFData,
} from "@types";

const LoaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledCircularLoader = styled(CircularLoader)`
  color: ${color("black")};
  height: 10rem;
  padding: 1rem;
  width: 10rem;
`;

export const FINANCING_COLLAPSABLE_CONTAINER =
  "financing-collapsable-container";

const Financing = ({
  journey,
  disabled,
  opened,
  revertProgress,
  isModifyVisible,
  isReadOnly = false,
  index = 2,
  errorMessages,
  withDeliveryInfo,
  isB2BSelected,
  isCurrentStep,
  generateContainerId = false,
  bankTransferPaymentCompleted = null,

  isLoading,
  cashData,
  lldData,
  loaData,
  vacData,
  scfData,
  onChange,
  deal,

  ...props
}) => {
  const {
    isPending: isLLDRequestPending,
    isError: isLLDRequestError,
  } = useLLDPriceBreakdownRequestStatusSelector();

  const {
    isPending: isLOARequestPending,
    isError: isLOARequestError,
  } = useLOAPriceBreakdownRequestStatusSelector();

  const {
    isPending: isVACRequestPending,
    isError: isVACRequestError,
  } = useVACPriceBreakdownRequestStatusSelector();

  const {
    isPending: isSCFRequestPending,
    isError: isSCFRequestError,
  } = useSCFPriceBreakdownRequestStatusSelector();

  const loaPriceBreakdown = useLOAPriceBreakdownSelector();
  const lldPriceBreakdown = useLLDPriceBreakdownSelector();
  const vacPriceBreakdown = useVACPriceBreakdownSelector();
  const scfPriceBreakdown = useSCFPriceBreakdownSelector();
  const quantity = useVehicleQuantitySelector();
  const { multipleVehicles: quantitySettings } = useGetJourneySpecificConfig();

  const isSCFJourneySelected = useIsSCFJourneySelected();

  const isQuantityVisible = quantitySettings?.enabled;

  const [isCollapsableContainerOpen, setIsCollapsableContainerOpen] = useState(
    opened ?? false
  );

  const isCashJourneySelected = getIsCashJourneySelected(journey);

  const getFinanceProductRelatedProps = () => {
    switch (journey) {
      case JOURNEY_TYPE.LLD:
        return {
          financialProductLabel: lldData.financialProductLabel,
          displayBlocks: lldData.displayBlocks,
          legalMention: lldData.legalMention,
          shouldDisplayError: isLLDRequestError,
          customizeButtonText:
            lldPriceBreakdown?.vehicleSimulation?.result.teaser.callToAction
              .text,
          isModifiable: isModifyVisible && !isLLDRequestPending,
        };

      case JOURNEY_TYPE.LOA:
        return {
          financialProductLabel: loaData.financialProductLabel,
          displayBlocks: loaData.displayBlocks,
          legalMention: loaData.legalMention,
          shouldDisplayError: isLOARequestError,
          customizeButtonText:
            loaPriceBreakdown?.vehicleSimulation?.result.teaser.callToAction
              .text,
          isModifiable: isModifyVisible && !isLOARequestPending,
        };

      case JOURNEY_TYPE.VAC:
        return {
          financialProductLabel: vacData?.financialProductLabel,
          displayBlocks: vacData?.displayBlocks,
          legalMention: vacData?.legalMention,
          shouldDisplayError: isVACRequestError,
          customizeButtonText:
            vacPriceBreakdown?.vehicleSimulation?.result.teaser.callToAction
              .text,
          isModifiable: isModifyVisible && !isVACRequestPending,
        };

      case JOURNEY_TYPE.SCF:
        return {
          financialProductLabel: scfData?.financialProductLabel,
          displayBlocks: scfData?.displayBlocks,
          legalMention: scfData?.legalMention,
          shouldDisplayError: isSCFRequestError,
          customizeButtonText:
            scfPriceBreakdown?.vehicleSimulation?.result.teaser.callToAction
              .detailText,
          isModifiable: isModifyVisible && !isSCFRequestPending,
        };

      default:
        throw new Error(
          `getFinanceProductRelatedProps can't be called on journey '${journey}'`
        );
    }
  };

  useEffect(() => {
    setIsCollapsableContainerOpen(opened);
  }, [opened]);

  const innerOnChange = currentIsOpen => {
    setIsCollapsableContainerOpen(!currentIsOpen);

    onChange?.(currentIsOpen);
  };

  const isFinanceProductDataMissing =
    getIsLeasingJourneySelected(journey) &&
    !getFinanceProductRelatedProps()?.financialProductLabel;

  const isLeasingJourneyError =
    isLLDRequestError ||
    isVACRequestError ||
    isLOARequestError ||
    isSCFRequestError;

  return (
    <CollapsableContainer
      title={
        <FinancingTitle
          titleAsHeader={isCurrentStep}
          journey={journey}
          vehicleQuantity={quantity}
        />
      }
      titleIndex={index}
      isOpen={isCollapsableContainerOpen}
      isDisabled={disabled}
      onChange={innerOnChange}
      id={
        FINANCING_COLLAPSABLE_CONTAINER +
        (generateContainerId ? `_${deal.gitId}` : "")
      }
      {...props}
    >
      <>
        {isLoading ||
        (isFinanceProductDataMissing && !isLeasingJourneyError) ? (
          <LoaderContainer>
            <StyledCircularLoader />
          </LoaderContainer>
        ) : (
          <>
            {isCashJourneySelected ? (
              <CashFinancing
                cashData={cashData}
                onModifyButtonClick={revertProgress}
                isModifiable={isModifyVisible}
                withDeliveryInfo={withDeliveryInfo}
                isB2BSelected={isB2BSelected}
                isQuantityVisible={isQuantityVisible}
                vehicleQuantity={quantity}
                deal={deal}
                bankTransferPaymentCompleted={bankTransferPaymentCompleted}
                isReadOnly={isReadOnly}
              />
            ) : (
              <>
                <FinanceFinancing
                  isWidgetEditable={!isSCFJourneySelected && !isReadOnly}
                  onModifyButtonClick={revertProgress}
                  withDeliveryInfo={withDeliveryInfo}
                  journey={journey}
                  isQuantityVisible={isQuantityVisible}
                  vehicleQuantity={quantity}
                  isReadOnly={isReadOnly}
                  deal={deal}
                  {...getFinanceProductRelatedProps()}
                />
                {isQuantityVisible && (
                  <TotalFinanceFinancing
                    journey={journey}
                    isQuantityVisible={isQuantityVisible}
                    vehicleQuantity={quantity}
                    {...getFinanceProductRelatedProps()}
                  />
                )}
              </>
            )}
            {errorMessages?.length > 0 &&
              errorMessages.map((errorMessage, index) => (
                <ErrorMessage key={index}>{errorMessage}</ErrorMessage>
              ))}
          </>
        )}
      </>
    </CollapsableContainer>
  );
};

Financing.propTypes = {
  disabled: bool,
  isOpen: bool,
  opened: bool,
  revertProgress: func,
  errorMessages: arrayOf(string),
  isReadOnly: bool,
  deal: dealItemType,
  index: number,
  isModifyVisible: bool,
  isB2BSelected: bool.isRequired,
  isCurrentStep: bool,

  onCollapseContainerChange: func,
  // these cannot be required, as it can be one or another,
  // or we are running first render before the redux state is updated with deal from SSR
  cashData: financingCashData,
  lldData: financingLLDData,
  loaData: financingLOAData,
  vacData: financingVACData,
  scfData: financingSCFData,
  journey: string.isRequired,
  isLoading: bool,
  isFinanceLoading: bool,
  withDeliveryInfo: bool,
  generateContainerId: bool,
  bankTransferPaymentCompleted: bool,
  onChange: func,
};

export default Financing;
