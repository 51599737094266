import React from "react";
import styled from "styled-components";
import { number, shape, string, bool } from "prop-types";
import { useTranslation } from "@lib/i18n";

import Typography from "./Typography";
import HorizontalLoader from "./HorizontalLoader";

import { getFormattedPrice } from "@shared/v2/lib/getFormattedPrice";
import { getIsCashJourneySelected } from "@shared/v2/lib/getIsCashJourneySelected";

import { prop, tablet, color, font } from "./utils";
import { useCurrencySettingsSelector } from "@redux/reducers/appConfig";
import { useIsB2BSelected } from "@hooks/useIsB2BSelected";

const Container = styled.div`
  color: ${prop("color")};
  font-size: ${({ fontSize }) =>
    fontSize.mobile ? fontSize.mobile : fontSize.default}rem;

  sup {
    font-size: ${({ fontSize }) => fontSize.default / 2}rem;
    padding-left: 0.25rem;
    text-transform: uppercase;
  }

  ${tablet`
    font-size: ${({ fontSize }) => fontSize.default}rem;
  `}
`;

const StyledTypography = styled(Typography)`
  display: inline;
  font-family: ${font("citroen")};
`;

const Comment = styled.a`
  display: ${({ isShowed }) => (isShowed ? "block" : "none")};
  font-size: ${({ fontSize }) => fontSize.default / 4}rem;
  color: ${color("white")};
`;

const PriceFormatter = ({
  amount,
  journey,
  color,
  currencySymbol,
  fontSizeInRem,
  firstPaymentAmount,
  monthlyTotalPrice,
  showOnlyCash,
  priceFormat,
  isVisible = true,
}) => {
  const { t } = useTranslation();
  const currencySettings = useCurrencySettingsSelector();

  const targetFormat = priceFormat ?? currencySettings.format;
  const targetCurrency = currencySymbol ?? currencySettings.symbol;

  const isCashJourneySelected = getIsCashJourneySelected(journey);

  const isB2BSelected = useIsB2BSelected();

  const downPayment = monthlyTotalPrice
    ? getFormattedPrice(monthlyTotalPrice, targetFormat, targetCurrency)
    : null;

  return (
    isVisible && (
      <Container fontSize={fontSizeInRem} color={color}>
        {showOnlyCash || isCashJourneySelected ? (
          <StyledTypography uppercase bold>
            {getFormattedPrice(amount, targetFormat, targetCurrency)}
          </StyledTypography>
        ) : (
          <div data-id="price-switcher-monthly-price">
            {monthlyTotalPrice ? (
              <StyledTypography uppercase bold size="md">
                {getFormattedPrice(
                  monthlyTotalPrice,
                  targetFormat,
                  targetCurrency
                )}
                <sup>
                  {isB2BSelected ? t("price.b2b.month") : t("price.b2c.month")}
                </sup>
              </StyledTypography>
            ) : (
              <HorizontalLoader />
            )}
            <Comment
              isShowed={firstPaymentAmount}
              href="#"
              fontSize={fontSizeInRem}
              data-id="price-switcher-price-comment"
            >
              {t("price.afterFirstPayment", { downPayment })}
              <div>{t("price.personalizeFinancing")}</div>
            </Comment>
          </div>
        )}
      </Container>
    )
  );
};

PriceFormatter.propTypes = {
  color: string.isRequired,
  fontSizeInRem: shape({
    default: number.isRequired,
    mobile: number,
  }).isRequired,
  firstPaymentAmount: number,
  monthlyTotalPrice: number,
  journey: string,
  showOnlyCash: bool,
  currencySymbol: string,
  isVisible: bool,
  amount: number,
  priceFormat: string,
};

export default PriceFormatter;
