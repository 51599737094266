import React from "react";
import styled, { useTheme } from "styled-components";
import { useTranslation, Trans } from "@lib/i18n";
import { bool, func, number } from "prop-types";
import getConfig from "next/config";
import { useRouter } from "next/router";
import api from "@api";

import Typography from "@components/shared/Typography";
import PriceFormatter from "@components/shared/PriceFormatter";
import Divider from "@components/shared/Divider";
import SimpleButton from "@components/shared/SimpleButton";

import { fontWeight, color, font } from "@components/shared/utils";
import { financingCashData, dealType } from "@types";
import { useDeliveryMethods } from "@hooks/useDeliveryMethods";
import { useGetFormattedPrice } from "@hooks/useGetFormattedPrice";
import {
  useCashDepositAmountSelector,
  useB2BCashDepositAmountSelector,
  useCashOutstandingBalanceSelector,
  useB2BCashOutstandingBalanceSelector,
  useEcoBonusSelector,
  useConfigurationSelector,
} from "@redux/reducers/appConfig";

import { useBusinessModelSelector } from "@redux/reducers/deal";
import { useIsUserTrustedSelector } from "@redux/reducers/auth";

import { DELIVERY_TYPE } from "@shared/constants";
import getPriceAlgorithm from "@shared/PriceAlgorithm/getPriceAlgorithm";
import { getIsCardImprintEnabled } from "@shared/helper/getIsCardImprintEnabled";
import { dealStatusAllowsPromoCodeChanges } from "@shared/helper/dealStatusAllowsPromoCodeChanges";
import { isIndexOrConfigPage } from "@shared/helper/isIndexOrConfigPage";
import CloseIcon from "components/shared/Icons/Close";
import { useDispatch } from "react-redux";
import {
  calculatePricesAction,
  postDealAction,
  removePromoCodeAction,
} from "redux/actions/dealActions";
import {
  CTA_SOL_TYPES,
  DEAL_TYPE,
  SESSION_STORAGE_KEYS,
} from "@shared/constants";
import { sessionStorageClient } from "utils/sessionStorageClient";

const { publicRuntimeConfig } = getConfig();

const { COUNTRY } = publicRuntimeConfig;

const PriceContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const StyledPriceContainer = styled(PriceContainer)`
  font-style: italic;
  margin: 0.25rem 0;
`;

const StyledList = styled.ul`
  margin: 0;
  list-style: "none";
  color: ${color("black")};
`;

const StyledListItem = styled.li`
  margin: 0.25rem 0;
`;

const ListItemPrimaryText = styled(Typography)`
  text-transform: uppercase;
  font-weight: ${({ regular }) =>
    regular ? fontWeight("regular") : fontWeight("bold")};
  font-family: ${font("citroen")};
`;

const ListItemSecondaryText = styled(Typography)`
  padding-left: 1rem;
`;

const ListTextContainer = styled.div`
  display: flex;
`;

const TaxTag = styled(Typography)`
  text-transform: uppercase;
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("regular")};
  padding-left: 0.5rem;
`;

const StyledTypography = styled(Typography)`
  font-family: ${font("citroen")};
`;

const VehicleQuantity = styled(Typography)`
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bold")};
`;

const SPromoCodeRemoveButton = styled.button`
  appearance: none;
  background: 0;
  border: 0;
  cursor: pointer;
  display: inline-grid;
  margin-left: 0.625rem;
  height: 1.28125rem;
  place-items: center;
  width: 1.28125rem;

  &:hover {
    color: ${color("errorRed")};
  }
`;

const CashFinancing = ({
  cashData,
  isModifiable,
  onModifyButtonClick,
  withDeliveryInfo,
  isB2BSelected,
  vehicleQuantity,
  isQuantityVisible,
  deal,
  bankTransferPaymentCompleted,
  isReadOnly,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const priceAlgorithm = getPriceAlgorithm(COUNTRY);
  const dispatch = useDispatch();

  const ecoBonus = useEcoBonusSelector();
  const businessModel = useBusinessModelSelector();

  const deliveryMethods = useDeliveryMethods(businessModel);
  const getFormattedPrice = useGetFormattedPrice();
  const depositAmount = useCashDepositAmountSelector();
  const b2bDepositAmount = useB2BCashDepositAmountSelector();
  const showOutstandingBalance = useCashOutstandingBalanceSelector();
  const showB2BOutstandingBalance = useB2BCashOutstandingBalanceSelector();
  const configuration = useConfigurationSelector();
  const isCardImprintEnabled = getIsCardImprintEnabled(
    configuration,
    isB2BSelected
  );
  const isUserTrusted = useIsUserTrustedSelector();
  const router = useRouter();

  const {
    carConfiguration,
    financeSimulation: { promoCode },
    status,
    userProfile,
  } = deal;

  const deliveryPrices = deliveryMethods.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.id]: getFormattedPrice(Number(cur.netPrice)),
    };
  }, {});

  const target = isB2BSelected ? "b2b" : "b2c";

  const removePromoCode = async () => {
    if (!isUserTrusted) {
      api.removePromoCodeFromSession();
    }

    dispatch(removePromoCodeAction());
    dispatch(calculatePricesAction());

    sessionStorageClient.removeItem(SESSION_STORAGE_KEYS.PROMOCODE);

    if (deal?.currentDeal?.token) {
      await dispatch(
        postDealAction(
          CTA_SOL_TYPES.CONFIGURATION_SAVED_BY_USER,
          DEAL_TYPE.DEAL,
          null,
          true
        )
      );
    }
  };

  return (
    <StyledList>
      <StyledListItem>
        <PriceContainer>
          <ListItemPrimaryText>
            {carConfiguration.preconfiguration.vehicle.label}
          </ListItemPrimaryText>
          <PriceFormatter
            showOnlyCash
            amount={cashData.basePrice}
            fontSizeInRem={{ default: 1 }}
            color={theme.colors.black}
            data-id="cashfinancing-baseprice"
          />
        </PriceContainer>
      </StyledListItem>
      {Boolean(cashData.options.length) && cashData.packPrice.netPrice > 0 && (
        <StyledListItem>
          <PriceContainer>
            <ListItemPrimaryText>
              {t("customize.pack")} {cashData.preconfigurationLabel}
            </ListItemPrimaryText>
            <PriceFormatter
              showOnlyCash
              amount={cashData.packPrice.netPrice}
              fontSizeInRem={{ default: 1 }}
              color={theme.colors.black}
              data-id="cashfinancing-packprice"
            />
          </PriceContainer>
        </StyledListItem>
      )}
      {Boolean(cashData.accessories.length) && (
        <StyledListItem>
          <PriceContainer>
            <ListItemPrimaryText>
              {t("customize.accessory")}
            </ListItemPrimaryText>
          </PriceContainer>
          {cashData.accessories.map(({ id, label, netPrice }) => (
            <PriceContainer key={id}>
              <ListItemSecondaryText>{label}</ListItemSecondaryText>
              <PriceFormatter
                showOnlyCash
                amount={Number(netPrice)}
                fontSizeInRem={{ default: 1 }}
                color={theme.colors.black}
                data-id={`cashfinancing-accessoryprice-${id}`}
              />
            </PriceContainer>
          ))}
        </StyledListItem>
      )}
      {Boolean(cashData.services.length) && (
        <StyledListItem>
          {cashData.services.map(({ id, label, netPrice }) => (
            <PriceContainer key={id}>
              <ListItemPrimaryText>{label}</ListItemPrimaryText>
              <PriceFormatter
                showOnlyCash
                amount={Number(netPrice)}
                fontSizeInRem={{ default: 1 }}
                color={theme.colors.black}
                data-id={`cashfinancing-serviceprice-${id}`}
              />
            </PriceContainer>
          ))}
        </StyledListItem>
      )}
      {cashData.isDeliveryIncluded && Number.isFinite(cashData.deliveryPrice) && (
        <StyledListItem>
          <PriceContainer>
            <ListItemPrimaryText>
              {userProfile.deliveryData.type === DELIVERY_TYPE.HOME
                ? t("customize.homeDelivery")
                : t("customize.delivery")}
            </ListItemPrimaryText>
            <PriceFormatter
              showOnlyCash
              amount={Number(cashData.deliveryPrice)}
              fontSizeInRem={{ default: 1 }}
              color={theme.colors.black}
              data-id="cashfinancing-deliveryprice"
            />
          </PriceContainer>
        </StyledListItem>
      )}

      {Boolean(cashData.registrationDiscountAmount) && (
        <StyledListItem>
          <PriceContainer>
            <ListItemPrimaryText>
              {t("customize.selfRegistration")}
            </ListItemPrimaryText>
            <PriceFormatter
              showOnlyCash
              amount={Number(cashData.registrationDiscountAmount * -1)}
              fontSizeInRem={{ default: 1 }}
              color={theme.colors.black}
              data-id="cashfinancing-registrationprice"
            />
          </PriceContainer>
        </StyledListItem>
      )}
      {!isIndexOrConfigPage(router.pathname) && promoCode?.valid && (
        <StyledListItem>
          <PriceContainer>
            <ListItemPrimaryText>
              {promoCode.label}
              {!isReadOnly && dealStatusAllowsPromoCodeChanges(status) && (
                <SPromoCodeRemoveButton
                  onClick={removePromoCode}
                  title={t("promoCodes.remove")}
                >
                  <CloseIcon size="sm" />
                </SPromoCodeRemoveButton>
              )}
            </ListItemPrimaryText>
            <PriceFormatter
              showOnlyCash
              amount={promoCode.discountAmount * -1}
              fontSizeInRem={{ default: 1 }}
              color={theme.colors.black}
              data-id="cashfinancing-promocode"
            />
          </PriceContainer>
        </StyledListItem>
      )}
      <Divider spacing={isQuantityVisible ? "0.5rem" : "2rem"} />
      <PriceContainer>
        <ListTextContainer>
          <ListItemPrimaryText>
            {isQuantityVisible
              ? t("basket.singleVehicleTotal")
              : t("basket.totalIncludingVat")}
          </ListItemPrimaryText>
        </ListTextContainer>
        <PriceFormatter
          showOnlyCash
          amount={cashData.totalPrice}
          fontSizeInRem={{ default: 1 }}
          color={theme.colors.black}
          data-id="cashfinancing-totalprice"
        />
      </PriceContainer>
      {isB2BSelected && !isQuantityVisible && (
        <StyledPriceContainer>
          <ListTextContainer>
            <ListItemPrimaryText regular>
              {t("basket.total")}
            </ListItemPrimaryText>
            <TaxTag>{t("basket.withoutVat")}</TaxTag>
          </ListTextContainer>
          <StyledTypography uppercase>
            {getFormattedPrice(cashData.totalPriceWithoutTax)}
          </StyledTypography>
        </StyledPriceContainer>
      )}
      {isQuantityVisible && (
        <>
          <Divider spacing="0.5rem" />
          <PriceContainer>
            <ListTextContainer>
              <ListItemPrimaryText>
                {t("basket.vehicleQuantityLabel")}
              </ListItemPrimaryText>
            </ListTextContainer>
            <VehicleQuantity>
              {t("basket.vehicleQuantityValue", {
                quantity: vehicleQuantity,
              })}
            </VehicleQuantity>
          </PriceContainer>
          <Divider spacing="0.5rem" />
          <PriceContainer>
            <ListTextContainer>
              <ListItemPrimaryText>
                {t("basket.totalIncludingVat")}
              </ListItemPrimaryText>
            </ListTextContainer>
            <PriceFormatter
              showOnlyCash
              amount={Number(
                priceAlgorithm.times(cashData.totalPrice, vehicleQuantity)
              )}
              fontSizeInRem={{ default: 1 }}
              color={theme.colors.black}
              data-id="cashfinancing-multi-totalprice"
            />
          </PriceContainer>
          {isB2BSelected && (
            <StyledPriceContainer>
              <ListTextContainer>
                <ListItemPrimaryText regular>
                  {t("basket.totalWithoutVat")}
                </ListItemPrimaryText>
              </ListTextContainer>
              <StyledTypography uppercase>
                {getFormattedPrice(
                  priceAlgorithm.times(
                    cashData.totalPriceWithoutTax,
                    vehicleQuantity
                  )
                )}
              </StyledTypography>
            </StyledPriceContainer>
          )}
        </>
      )}

      {ecoBonus.enabled && (
        <Typography size="sm" margin="1rem 0 0 0">
          {isQuantityVisible
            ? t(`price.${target}.multipleVehiclesEcologicalBonus`, {
                ecoBonusPerVehicle: getFormattedPrice(
                  Number(ecoBonus.netAmount)
                ),
                quantity: vehicleQuantity,
                total: getFormattedPrice(
                  Number(
                    priceAlgorithm.times(ecoBonus.netAmount, vehicleQuantity)
                  )
                ),
              })
            : t(`price.${target}.ecologicalBonus`, {
                amount: getFormattedPrice(Number(ecoBonus.netAmount)),
              })}
        </Typography>
      )}

      {isCardImprintEnabled && (
        <Typography size="sm" margin="0.5rem 0">
          {t(`price.${target}.cardImprint`)}
        </Typography>
      )}

      {withDeliveryInfo && (
        <Typography size="sm" margin="0.5rem 0">
          <Trans
            i18nKey="price.deliveryInfo.cash"
            values={{ deliveryPrices }}
          />
        </Typography>
      )}

      {isModifiable && (
        <SimpleButton
          withArrow
          onClick={onModifyButtonClick}
          data-id="basket-financing-modify-button"
        >
          {t("general.modify")}
        </SimpleButton>
      )}

      {/* Deposit payment */}
      {((!isB2BSelected && showOutstandingBalance) ||
        (isB2BSelected && showB2BOutstandingBalance)) && (
        <>
          <PriceContainer>
            <ListTextContainer>
              <ListItemPrimaryText>
                {bankTransferPaymentCompleted
                  ? t("myOrder.downPaymentCompleted")
                  : t("basket.totalDepositPayable")}
              </ListItemPrimaryText>
            </ListTextContainer>
            <PriceFormatter
              showOnlyCash
              amount={isB2BSelected ? b2bDepositAmount : depositAmount}
              fontSizeInRem={{ default: 1 }}
              color={theme.colors.black}
              data-id="cashfinancing-totalDeposit"
            />
          </PriceContainer>

          {/* Outstanding balance payable on shipping*/}
          {!bankTransferPaymentCompleted && (
            <PriceContainer>
              <ListTextContainer>
                <ListItemPrimaryText>
                  {t("basket.outstandingBalance")}
                </ListItemPrimaryText>
              </ListTextContainer>
              <PriceFormatter
                showOnlyCash
                amount={
                  cashData.totalPrice -
                  (isB2BSelected ? b2bDepositAmount : depositAmount)
                }
                fontSizeInRem={{ default: 1 }}
                color={theme.colors.black}
                data-id="cashfinancing-outstandingBalance"
              />
            </PriceContainer>
          )}

          {/* bank transfer payment */}
          {bankTransferPaymentCompleted && (
            <PriceContainer>
              <ListTextContainer>
                <ListItemPrimaryText>
                  {t("myOrder.balancePaymentCompleted")}
                </ListItemPrimaryText>
              </ListTextContainer>
              <PriceFormatter
                showOnlyCash
                amount={bankTransferPaymentCompleted.amount}
                fontSizeInRem={{ default: 1 }}
                color={theme.colors.black}
                data-id="cashfinancing-bankTransferPayment"
              />
            </PriceContainer>
          )}
        </>
      )}
    </StyledList>
  );
};

CashFinancing.propTypes = {
  isB2BSelected: bool.isRequired,
  deal: dealType.isRequired,
  cashData: financingCashData,
  isModifiable: bool,
  onModifyButtonClick: func,
  withDeliveryInfo: bool,
  vehicleQuantity: number,
  isQuantityVisible: bool,
  bankTransferPaymentCompleted: bool,
  isReadOnly: bool,
};

export default CashFinancing;
