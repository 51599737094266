import { useDeliveryMethodsSelector } from "@redux/reducers/appConfig";
import { getIsB2BSelected } from "@shared/v2/lib/getIsB2BSelected";
import { BUSINESS_MODELS_TARGET_KEYS } from "@shared/constants";

export const useDeliveryMethods = businessModel => {
  const deliveryMethods = useDeliveryMethodsSelector();
  const isB2BSelected = getIsB2BSelected(businessModel);

  // This can removed after the next production release
  const isFallbackRequired = deliveryMethods.some(
    deliveryMethod => deliveryMethod.b2B === undefined
  );

  if (isFallbackRequired) {
    return deliveryMethods;
  }

  const targetKey = isB2BSelected
    ? BUSINESS_MODELS_TARGET_KEYS.B2B
    : BUSINESS_MODELS_TARGET_KEYS.B2C;

  return deliveryMethods.filter(
    deliveryMethod => deliveryMethod[targetKey] === true
  );
};
