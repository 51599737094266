import React, { useMemo } from "react";
import styled from "styled-components";
import bbobReactRender from "@bbob/react/es/render";
import presetReact from "@bbob/preset-react";
import { bool, func, arrayOf, object } from "prop-types";

import Typography from "../shared/Typography";
import Modal from "../shared/Modal";

import { getParsedLegalMention } from "@shared/v2/lib/getParsedLegalMention";
import { useTranslation } from "@lib/i18n";

const StyledModalContainer = styled.div`
  padding: 0 1rem;
`;

const LegalMentionModal = ({ isOpen, onClose, legalMention }) => {
  const { t } = useTranslation();

  const parsedLegalMentions = useMemo(() => {
    return getParsedLegalMention(legalMention);
  }, [legalMention]);

  return (
    <Modal
      isOpen={isOpen}
      withCloseIcon
      ariaLabel={t("basket.financing.legalMention")}
      onClose={onClose}
    >
      <StyledModalContainer>
        {parsedLegalMentions.map((text, index) => (
          <Typography size="sm" margin=".5rem 0" key={index}>
            <span>
              {bbobReactRender(text, presetReact(), {
                onlyAllowTags: ["url"],
              })}
            </span>
          </Typography>
        ))}
      </StyledModalContainer>
    </Modal>
  );
};

LegalMentionModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  legalMention: arrayOf(object).isRequired,
};

export default LegalMentionModal;
