import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Container = styled.span`
  display: inline-block;
`;

const Dot = styled(motion.span)`
  display: inline-block;
`;

const movement = {
  start: { y: 0 },
  animated: {
    y: -5,
    transition: {
      yoyo: Infinity,
      repeatDelay: 0.4,
      duration: 0.3,
      type: "tween",
      easing: "anticipate",
    },
  },
};

const LoadingDots = () => {
  return (
    <Container>
      <Dot variants={movement}>.</Dot>
      <Dot variants={movement}>.</Dot>
      <Dot variants={movement}>.</Dot>
    </Container>
  );
};

export default LoadingDots;
