import React from "react";
import { string, number, bool } from "prop-types";
import { useTranslation } from "@lib/i18n";
import styled from "styled-components";

import { font, fontSize, fontWeight } from "@components/shared/utils";
import { getIsLOAJourneySelected } from "@shared/v2/lib/getIsLOAJourneySelected";
import { getIsLeasingJourneySelected } from "@shared/v2/lib/getIsLeasingJourneySelected";
import Typography from "@components/shared/Typography";

const HeaderTypography = styled(Typography)`
  font-weight: ${fontWeight("bold")};
  font-family: ${font("citroen")};
  font-size: ${fontSize("md")};
  text-transform: lowercase;
  user-select: none;
`;

const Asterisk = styled.span`
  font-size: ${fontSize("xl")};
`;

const FinancingTitle = ({ journey, vehicleQuantity, titleAsHeader }) => {
  const { t } = useTranslation();
  if (getIsLeasingJourneySelected(journey)) {
    return (
      <HeaderTypography
        customColor="currentColor"
        forwardedAs={titleAsHeader && "h2"}
      >
        {t("basket.myFunding")}
        {getIsLOAJourneySelected(journey) && <Asterisk>*</Asterisk>}
      </HeaderTypography>
    );
  }

  return (
    <HeaderTypography
      customColor="currentColor"
      forwardedAs={titleAsHeader && "h2"}
    >
      {t(
        vehicleQuantity > 1
          ? "basket.myPayment.plural"
          : "basket.myPayment.singular"
      )}
    </HeaderTypography>
  );
};

FinancingTitle.propTypes = {
  journey: string.isRequired,
  vehicleQuantity: number,
  titleAsHeader: bool,
};

export default FinancingTitle;
