import { number, string } from "prop-types";
import styled from "styled-components";
import { color, prop } from "@components/shared/utils";

const DEFAULT_SIZE = 20;

const InfoIcon = styled.span`
  display: block;
  position: relative;
  width: ${({ size }) => `${size ?? DEFAULT_SIZE}px`};
  height: ${({ size }) => `${size ?? DEFAULT_SIZE}px`};
  background: currentColor;
  border-radius: 50%;

  &:before {
    content: "";
    display: block;
    background: ${prop("color") || color("white")};
    height: 45%;
    width: 12%;
    position: absolute;
    left: 50%;
    bottom: 18%;
    transform: translate(-50%, 0);
  }

  &:after {
    content: "";
    display: block;
    background: ${prop("color") || color("white")};
    height: 12%;
    width: 12%;
    position: absolute;
    left: 50%;
    top: 18%;
    transform: translate(-50%, 0);
  }
`;

InfoIcon.propTypes = {
  size: number,
  color: string,
};

export default InfoIcon;
