import gtm from "@gtm/core";
import { useRouter } from "next/router";
import { useTranslation } from "@lib/i18n";

import { useSetQueryParameters } from "@hooks/useSetQueryParameters";
import { openFinanceWidgetEvent } from "@gtm/events/shared";

export const useOpenFinanceWidget = () => {
  const { t } = useTranslation();
  const setQueryParameters = useSetQueryParameters();
  const router = useRouter();

  return () => {
    const { route } = router;

    gtm.fire(
      openFinanceWidgetEvent(t("price.personalizeFinancingTitle"), route)
    );

    setQueryParameters({
      financialWidget: "open",
    });
  };
};
