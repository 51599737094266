import { getSolJourneyConfigForJourney } from "@shared/v2/lib/getSolJourneyConfigForJourney";
import { useConfigurationSelector } from "@redux/reducers/appConfig";

import {
  useBusinessModelSelector,
  useSelectedJourneySelector,
} from "@redux/reducers/deal";

export const useGetJourneySpecificConfig = () => {
  const journey = useSelectedJourneySelector();
  const configuration = useConfigurationSelector();
  const businessModel = useBusinessModelSelector();

  return getSolJourneyConfigForJourney(journey, businessModel, configuration);
};
