import React from "react";
import { motion } from "framer-motion";
import { number } from "prop-types";
import BaseIcon from "../BaseIcon";

const CircularLoader = props => (
  <BaseIcon viewBox="0 0 100 100" {...props}>
    <motion.path
      d="M95 50C95 74.8528 74.8528 95 50 95C25.1472 95 5 74.8528 5 50C5 25.1472 25.1472 5 50 5C74.8528 5 95 25.1472 95 50Z"
      fill="none"
      stroke="currentColor"
      strokeWidth={props.strokeWidth ?? 5}
      pathLength={100}
      strokeDasharray={100}
      strokeDashoffset={100}
      animate={{ strokeDashoffset: [100, 40, -100], rotate: [0, 180, 360] }}
      transition={{ duration: 1.8, times: [0, 0.45, 1], loop: Infinity }}
    />
  </BaseIcon>
);

CircularLoader.propTypes = {
  strokeWidth: number,
};

export default CircularLoader;
